import React from 'react';

const VaccinationCervicalCancer = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Vaccination of Cervical Cancer</h1>
      <p className="text-lg mb-4">
        Vaccination against cervical cancer involves administering vaccines that protect against the human papillomavirus (HPV), which is a major cause of cervical cancer.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Available Vaccines</h2>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Gardasil</li>
        <li>Cervarix</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our vaccination services are provided by experienced healthcare professionals, ensuring safe and effective protection against cervical cancer.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have any questions or would like to schedule a vaccination appointment, please contact us.
      </p>
    </div>
  );
};

export default VaccinationCervicalCancer;
