import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slider1 from '../../Images/slider1.png';
import slider2 from '../../Images/slider2.png';

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const carouselStyles = {
    width: '100%', // Set width to fill the container
  };

  const imageStyles = {
    height: '700px', // Set your desired height
    objectFit: 'cover', // Ensure the image covers the area without distortion
    width: '100%', // Make sure the image covers the entire width of the container
  };
  return (
    <>
      {/* <div className="banner z-0">
        <Slider {...settings}>
          <div className="relative w-full h-screen">
            <img
              src={slider1}
              alt="Banner 1"
              className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <h1 className="text-white text-center text-2xl md:text-4xl lg:text-5xl font-bold p-4">
                {tagLine}
              </h1>
            </div>
          </div>
          <div className="relative w-full h-screen">
            <img
              src={slider2}
              alt="Banner 2"
              className="absolute inset-0 w-full h-full object-contain"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <h1 className="text-white text-center text-2xl md:text-4xl lg:text-5xl font-bold p-4">
                {tagLine}
              </h1>
            </div>
          </div>
        </Slider>
      </div> */}

      {/* ----------------------------------------- */}
      <div className="slider">
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          style={carouselStyles}
        >
          <div>
            <img src={slider1} alt="Slide 1" style={imageStyles} />
            {/* <p className="legend">Cataract Surgery</p> */}
          </div>
          <div>
            <img src={slider2} alt="Slide 2" style={imageStyles} />
            {/* <p className="legend">Lasik Surgery</p> */}
          </div>
          <div>
            <img src={slider1} alt="Slide 3" style={imageStyles} />
            {/* <p className="legend">Cataract Treatment</p> */}
          </div>
          <div>
            <img src={slider2} alt="Slide 4" style={imageStyles} />
            {/* <p className="legend">ICL Surgery</p> */}
          </div>
          {/* Add more slides as needed */}
        </Carousel>
      </div>
    </>
  );
};

export default Banner;
