import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaLocationArrow,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaEnvelope,
  FaPhone,
  FaPinterest,
  FaYoutube,
} from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className="bg-primary text-black py-8 px-5">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between">
          {/* Links Section */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8 ">
            <h3 className="text-lg font-bold mb-4 ml-5">About</h3>
            <ul>
              <li>
                <Link
                  to="/about"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Dr. Charu Maheshwari
                </Link>
              </li>
              <li>
                <Link
                  to="/photo-gallery"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Photo Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="/testimonials"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>

          {/* Gynaecology Links */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8 ">
            <h3 className="text-lg font-bold mb-4 ml-5">Gynaecology</h3>
            <ul>
              <li>
                <Link
                  to="/gynaecology/adolescent-medicine"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Adolescent Medicine
                </Link>
              </li>
              <li>
                <Link
                  to="/gynaecology/menstrual-problems"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Menstrual Problems
                </Link>
              </li>
              <li>
                <Link
                  to="/gynaecology/pcos"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  PCOS
                </Link>
              </li>
              <li>
                <Link
                  to="/gynaecology/urinary-problems"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Urinary Problems
                </Link>
              </li>
              <li>
                <Link
                  to="/gynaecology/vaginal-discharge"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Vaginal Discharge
                </Link>
              </li>
            </ul>
          </div>

          {/* Obstetrics Links */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8 ">
            <h3 className="text-lg font-bold mb-4 ml-5">Obstetrics</h3>
            <ul>
              <li>
                <Link
                  to="/obstetrics/pre-pregnancy-care"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Pre Pregnancy Care
                </Link>
              </li>
              <li>
                <Link
                  to="/obstetrics/pregnancy-care"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Pregnancy Care
                </Link>
              </li>
              <li>
                <Link
                  to="/obstetrics/laparoscopy-ectopic-pregnancy"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Laparoscopy
                </Link>
              </li>
              <li>
                <Link
                  to="/obstetrics/recurrent-pregnancy-loss"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Recurrent Pregnancy Loss
                </Link>
              </li>
              <li>
                <Link
                  to="/obstetrics/pregnancy-ultrasound-scan"
                  className="block px-4 py-2 hover:text-yellow-500 transition duration-300"
                >
                  Pregnancy Ultrasound Scan
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Us Link */}
          <div className="w-full md:w-1/2 lg:w-1/4 mb-8 ">
            <h3 className="text-lg font-bold mb-4">Contact Us</h3>

            <div className="flex items-center mb-2">
              <FaPhone className="mr-2" />
              <a
                target="_blank"
                href="tel:+1234567890"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                9826071007
              </a>
            </div>
            <div className="flex items-center mb-2">
              <FaLocationDot className="mr-2 md:text-2xl " />
              <div>
                Shri Mangal Nagar, 377, Bicholi Hapsi Main Rd, near Rajwadi Pan Shop, Shukh Shanti Nagar, Indore, Madhya Pradesh 452016
              </div>
            </div>
            <div className="flex items-center mb-2">
              <FaEnvelope className="mr-2" />
              <a
                target="_blank"
                href="mailto:drcharumaheshwari02@gmail.com"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                drcharumaheshwari02@gmail.com
              </a>
            </div>
            <div className="flex items-center mb-2">
              <FaWhatsapp className="mr-2" />
              <a
                target="_blank"
                href="https://wa.me/9826071007"
                className="hover:text-yellow-500 transition duration-300"
                rel="noreferrer"
              >
                WhatsApp Us
              </a>
            </div>
          </div>
        </div>

        {/* Social Media Section */}
        <div className="flex justify-center space-x-6 mt-8">
          <a
            href="https://www.facebook.com/drcharumaheshwarigynecologist"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow transition duration-300"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/drcharumaheshwari/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow transition duration-300"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/in/dr-charu-maheshwari-3babb2317/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow transition duration-300"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://www.pinterest.com/drcharumaheshwari/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow transition duration-300"
          >
            <FaPinterest />
          </a>
          <a
            href="https://www.youtube.com/@DrCharuMaheshwari"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow transition duration-300"
          >
            <FaYoutube />
          </a>
        </div>

        {/* Copyright Section */}
        <div className="text-center mt-8">
          <p className="text-sm">
            &copy; 2024{' '}
            <Link
              to="https://r4healthcare.in/"
              target="_blank"
              rel="noopener"
              className="underline underline-offset-4 text-yellow font-bold"
            >
              R4 HealthCare
            </Link>{' '}
            . All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
