import React from 'react';

const PregnancyUltrasoundScan = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Pregnancy Ultrasound Scan</h1>
      <p className="text-lg mb-4">
        A pregnancy ultrasound scan is a valuable diagnostic tool used to monitor fetal development and assess the health of both mother and baby during pregnancy. Our clinic offers state-of-the-art ultrasound services to provide comprehensive care and peace of mind throughout your pregnancy journey.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Purpose of Pregnancy Ultrasound Scan</h2>
      <p className="text-lg mb-4">
        Ultrasound scans during pregnancy serve several purposes, including:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Confirming pregnancy and estimating gestational age</li>
        <li>Monitoring fetal growth and development</li>
        <li>Detecting multiple pregnancies or abnormalities</li>
        <li>Assessing the placenta and amniotic fluid levels</li>
        <li>Evaluating the uterus and cervix for abnormalities</li>
        <li>Guiding medical procedures, such as amniocentesis or fetal surgeries</li>
        <li>Providing reassurance and bonding opportunities for parents</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Types of Ultrasound Scans</h2>
      <p className="text-lg mb-4">
        Depending on your stage of pregnancy and specific medical needs, our ultrasound services may include:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Early pregnancy ultrasound (dating scan)</li>
        <li>Mid-pregnancy anatomy scan (20-week scan)</li>
        <li>Third-trimester scans for growth and well-being</li>
        <li>Specialized scans for high-risk pregnancies or specific concerns</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Your Pregnancy Ultrasound Experience</h2>
      <p className="text-lg mb-4">
        Our skilled sonographers and healthcare team are dedicated to providing a comfortable and informative ultrasound experience. We prioritize clear communication, ensuring you understand the findings and implications of each scan. Your well-being and peace of mind are our top priorities.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Schedule Your Ultrasound Appointment</h2>
      <p className="text-lg mb-4">
        If you're expecting or have questions about pregnancy ultrasound scans, contact our clinic to schedule your appointment. We look forward to supporting you with expert care and compassion throughout your pregnancy journey.
      </p>
    </div>
  );
};

export default PregnancyUltrasoundScan;
