import React from 'react';

const PrePregnancyCare = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Pre Pregnancy Care</h1>
      <p className="text-lg mb-4">
        Pre-pregnancy care involves taking steps to optimize your health and prepare for a healthy pregnancy. Whether you're planning to conceive soon or in the future, proactive care can significantly impact your reproductive health and the well-being of your future baby.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Key Aspects of Pre Pregnancy Care</h2>
      <p className="text-lg mb-4">
        Our clinic offers comprehensive pre-pregnancy care services tailored to your individual needs. This includes:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Health assessment and medical history review</li>
        <li>Screening for genetic disorders or risk factors</li>
        <li>Nutritional counseling and supplements to optimize fertility</li>
        <li>Management of chronic health conditions (e.g., diabetes, hypertension)</li>
        <li>Review of medications and vaccinations</li>
        <li>Lifestyle recommendations, including diet, exercise, and stress management</li>
        <li>Fertility evaluation and treatment, if necessary</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Pre Pregnancy Care Matters</h2>
      <p className="text-lg mb-4">
        Preparing your body for pregnancy can increase the likelihood of a healthy conception, pregnancy, and childbirth. It allows you to address any potential risks or health concerns early, ensuring a smooth transition into parenthood.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Schedule Your Consultation</h2>
      <p className="text-lg mb-4">
        If you're planning to start a family or have questions about pre-pregnancy care, contact our clinic to schedule a consultation. Our dedicated team is here to support you on your journey to parenthood with personalized care and expert guidance.
      </p>
    </div>
  );
};

export default PrePregnancyCare;
