import React from 'react';

const PCOS = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">
        Polycystic Ovary Syndrome (PCOS)
      </h1>
      <p className="text-lg mb-4">
        Polycystic Ovary Syndrome (PCOS) is a hormonal disorder common among
        women of reproductive age. It affects the ovaries and can cause
        irregular menstrual cycles, excess androgen levels (male hormones), and
        small fluid-filled sacs (cysts) in the ovaries.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Symptoms of PCOS</h2>
      <p className="text-lg mb-4">
        Symptoms of PCOS can vary widely and may include:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Irregular periods or no periods at all</li>
        <li>
          Excessive hair growth (hirsutism) on the face, chest, back, or
          buttocks
        </li>
        <li>Acne or oily skin</li>
        <li>Weight gain or difficulty losing weight</li>
        <li>
          Thinning hair or hair loss from the scalp (male-pattern baldness)
        </li>
        <li>Skin tags, darkening of the skin, or patches of thickened skin</li>
        <li>Difficulty getting pregnant (due to irregular ovulation)</li>
        <li>Mood swings or depression</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Diagnosis and Treatment</h2>
      <p className="text-lg mb-4">
        Diagnosing PCOS involves a combination of medical history review,
        physical examination, blood tests to measure hormone levels, and imaging
        tests (such as ultrasound) to examine the ovaries. Treatment focuses on
        managing symptoms and reducing the risk of complications:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>
          Lifestyle changes: Healthy diet, regular exercise, and weight
          management
        </li>
        <li>
          Medications: Birth control pills to regulate menstrual cycles,
          anti-androgen medications to reduce symptoms like excess hair growth,
          and fertility medications for women trying to conceive
        </li>
        <li>
          Management of associated conditions: Treatment for acne, infertility,
          or other symptoms as needed
        </li>
        <li>
          Emotional support and counseling: Addressing the emotional impact of
          PCOS and providing support for mental health
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our clinic offers specialized care for women with PCOS, providing
        personalized treatment plans tailored to each patient's unique needs. We
        are committed to helping women manage their symptoms, improve their
        overall health, and achieve their reproductive goals.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you suspect you have PCOS or have been diagnosed and are seeking
        comprehensive care, please contact us to schedule an appointment. Our
        compassionate team is here to support you on your journey to better
        health.
      </p>
    </div>
  );
};

export default PCOS;
