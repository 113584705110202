import React from 'react';

const AdolescentMedicine = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Adolescent Medicine</h1>
      <p className="text-lg mb-4">
        Adolescence is a critical period of development, marked by significant
        physical, emotional, and social changes. Our Adolescent Medicine
        services are dedicated to addressing the unique healthcare needs of
        teenagers and young adults.
      </p>
      <h2 className="text-2xl font-semibold mb-2">
        Comprehensive Care for Adolescents
      </h2>
      <p className="text-lg mb-4">
        We provide a wide range of services to support the health and well-being
        of adolescents, including:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Physical exams and preventive care</li>

      
        <li>Guidance on nutrition and physical activity</li>
        <li>Reproductive health care and education</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our team of experienced healthcare professionals is dedicated to
        providing compassionate, personalized care to every adolescent we serve.
        We understand the challenges that teenagers face and are here to support
        them through this important stage of life.
      </p>
      <h2 className="text-2xl font-semibold mb-2">
        Confidential and Respectful Care
      </h2>
      <p className="text-lg mb-4">
        We prioritize the privacy and dignity of our adolescent patients. Our
        services are designed to be confidential and respectful, ensuring that
        teenagers feel comfortable seeking the care they need.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have any questions or would like to schedule an appointment,
        please don't hesitate to contact us. We're here to help you navigate the
        challenges of adolescence with confidence and support.
      </p>
    </div>
  );
};

export default AdolescentMedicine;
