import React from 'react';

const PregnancyCare = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Pregnancy Care</h1>
      <p className="text-lg mb-4">
        Pregnancy care is essential for ensuring the health and well-being of both mother and baby throughout the pregnancy journey. Our clinic provides comprehensive prenatal care to monitor the progress of your pregnancy and address any concerns that may arise.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Comprehensive Prenatal Services</h2>
      <p className="text-lg mb-4">
        We offer a range of prenatal services to support you during pregnancy, including:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Regular prenatal check-ups and screenings</li>
        <li>Ultrasound scans to monitor fetal development</li>
        <li>Nutritional counseling and prenatal vitamins</li>
        <li>Management of pregnancy-related discomforts</li>
        <li>Screening for gestational diabetes and other pregnancy complications</li>
        <li>Preparation for labor and childbirth</li>
        <li>Education on childbirth options and breastfeeding</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Personalized Care and Support</h2>
      <p className="text-lg mb-4">
        Our team is dedicated to providing personalized care, addressing your unique needs and preferences throughout your pregnancy journey. We strive to empower you with knowledge and support to make informed decisions for a healthy pregnancy and delivery.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Schedule Your Prenatal Appointment</h2>
      <p className="text-lg mb-4">
        If you're expecting or planning to become pregnant, contact our clinic to schedule your prenatal care appointment. We are committed to ensuring a positive pregnancy experience and supporting your journey to parenthood.
      </p>
    </div>
  );
};

export default PregnancyCare;
