import React from 'react';

const Laparoscopy = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Laparoscopy for Ectopic Pregnancy</h1>
      <p className="text-lg mb-4">
        Laparoscopy is a minimally invasive surgical procedure used to diagnose and treat various gynecological conditions, including ectopic pregnancy. It involves inserting a small camera (laparoscope) through tiny incisions in the abdomen to visualize and access the reproductive organs.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Benefits of Laparoscopy</h2>
      <p className="text-lg mb-4">
        Compared to traditional open surgery, laparoscopy offers several benefits, including:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Smaller incisions and reduced scarring</li>
        <li>Quicker recovery time and shorter hospital stay</li>
        <li>Less postoperative pain and discomfort</li>
        <li>Lower risk of complications, such as infection</li>
        <li>Clearer visualization of pelvic structures for precise diagnosis</li>
        <li>Ability to perform surgical procedures with precision (e.g., removing ectopic pregnancy)</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Your Laparoscopy Procedure</h2>
      <p className="text-lg mb-4">
        During laparoscopy for ectopic pregnancy, our experienced gynecologists will carefully examine your reproductive organs to confirm the diagnosis and, if necessary, perform surgical intervention to remove the ectopic pregnancy while preserving your fertility whenever possible.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Recovery and Follow-Up Care</h2>
      <p className="text-lg mb-4">
        After your laparoscopic procedure, our team will provide detailed postoperative instructions to support your recovery. We will schedule follow-up appointments to monitor your healing progress and address any concerns you may have.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have been diagnosed with an ectopic pregnancy or require gynecological surgery, including laparoscopy, please contact our clinic to schedule a consultation. Our compassionate team is here to provide expert care and support during this challenging time.
      </p>
    </div>
  );
};

export default Laparoscopy;
