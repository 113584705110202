import React from 'react';

const Testimonials = () => {
  return (
    <div className="container mx-auto my-10 p-6">
      <h1 className="text-3xl font-bold mb-4">Testimonials</h1>
      <div className="space-y-4">
        <div className="p-4 bg-gray-100 rounded shadow-lg">
          <p className="mb-2">
            "Dr. Charu Maheshwari provided excellent care and support during my
            pregnancy..."
          </p>
          <span>- Patient A</span>
        </div>
        <div className="p-4 bg-gray-100 rounded shadow-lg">
          <p className="mb-2">
            "I highly recommend Dr. Maheshwari for her expertise and
            compassion..."
          </p>
          <span>- Patient B</span>
        </div>
        <div className="p-4 bg-gray-100 rounded shadow-lg">
          <p className="mb-2">
            "The best gynaecologist I've ever met. She listens and provides
            personalized care..."
          </p>
          <span>- Patient C</span>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
