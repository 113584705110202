import React from 'react';

const RecurrentPregnancyLoss = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Recurrent Pregnancy Loss</h1>
      <p className="text-lg mb-4">
        Recurrent pregnancy loss (RPL), also known as recurrent miscarriage, is
        defined as the loss of two or more consecutive pregnancies before 20
        weeks of gestation. It can be emotionally and physically challenging,
        requiring specialized medical care and support.
      </p>
      <h2 className="text-2xl font-semibold mb-2">
        Causes of Recurrent Pregnancy Loss
      </h2>
      <p className="text-lg mb-4">
        Our clinic specializes in identifying and addressing the underlying
        causes of recurrent pregnancy loss, which may include:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Chromosomal abnormalities in the fetus</li>
        <li>Hormonal imbalances, such as thyroid disorders or diabetes</li>
        <li>Uterine abnormalities, including fibroids or septate uterus</li>
        <li>Autoimmune disorders affecting pregnancy</li>
        <li>Infections or reproductive tract abnormalities</li>
        <li>
          Lifestyle factors, such as smoking, alcohol consumption, or advanced
          maternal age
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Evaluation and Treatment</h2>
      <p className="text-lg mb-4">
        Our team conducts thorough evaluations, including genetic testing,
        hormonal assessments, imaging studies, and uterine evaluations, to
        determine the cause of recurrent pregnancy loss. Treatment options may
        include:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Medical management to address underlying health conditions</li>
        <li>
          Fertility treatments, such as assisted reproductive technologies (ART)
        </li>
        <li>Surgical interventions to correct uterine abnormalities</li>
        <li>Counseling and emotional support for couples</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">
        Supportive Care and Follow-Up
      </h2>
      <p className="text-lg mb-4">
        Our clinic offers compassionate care and support throughout your journey
        with recurrent pregnancy loss. We collaborate closely with you to
        develop personalized treatment plans and provide ongoing monitoring and
        follow-up care to optimize your chances of a successful pregnancy.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have experienced recurrent pregnancy loss or have concerns about
        your fertility, please contact our clinic to schedule a consultation.
        Our experienced team is dedicated to helping you achieve a healthy and
        successful pregnancy.
      </p>
    </div>
  );
};

export default RecurrentPregnancyLoss;
