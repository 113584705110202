import React from 'react';
import { Link } from 'react-router-dom';

const DoctorInfo = () => {
  return (
    <section className="p-8 relative overflow-hidden ">
      {/* Background element with rotated background */}
      <div className="absolute inset-0 bg-primary transform -skew-y-12 py-10 origin-top-left"></div>

      <div className="container mx-auto flex flex-col md:flex-row items-center relative z-10">
        {/* Content section */}
        <div className="md:w-1/2 md:ml-8 relative z-20">
          <div className="bg-primary py-6 px-8 rounded-lg shadow-lg transform -skew-y-12">
            <h2 className="text-3xl font-bold mb-4">
              Dr. Charu Maheshwari M.B.B.S., DNB (Obs., & Gynaecology)
            </h2>
            <p className="mb-4">
              Obstetrician & Gynecologist, Infertility Specialist & Gynae
              Endoscopic Surgeon
            </p>
            <p className="mb-4">
              Dr. Charu Maheshwari is a compassionate and experienced
              obstetrician-gynecologist who provides comprehensive care for
              women of all ages. She has extensive training in various
              gynecological procedures, including laparoscopy. Dr. Charu
              Maheshwari is committed to providing individualized care and
              ensuring that each patient feels comfortable and supported
              throughout their treatment. Visit the clinic at Dr. Charu
              Maheshwari in Indore to experience her expertise and dedication to
              women's health. Dr. Charu Maheshwari offers comprehensive women's
              healthcare in Indore, Madhya Pradesh. Her expertise spans
              obstetrics, gynecology, laparoscopic surgery, and infertility
              treatment.
            </p>
            <Link to="/about">
              <div className="text-center border-2 border-yellow p-2 hover:bg-yellow rounded-md">
                Know More
              </div>
            </Link>
          </div>
        </div>

        {/* Image section */}
        <div className="md:w-1/2 relative py-10 z-20">
          <div className="overflow-hidden rounded-lg shadow-lg transform skew-y-12">
            <img
              src="https://lh3.googleusercontent.com/p/AF1QipOQk9JNcQbGECFbtT_BvJIWPQOw6rruwVXBFaEO=s1360-w1360-h1020"
              alt="Dr. Arpana Jain"
              className="object-cover h-1/2"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DoctorInfo;
