import React from 'react';

const IVF = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">In Vitro Fertilization (IVF)</h1>
      <p className="text-lg mb-4">
        In Vitro Fertilization (IVF) is a complex series of procedures used to
        help with fertility or prevent genetic problems and assist with the
        conception of a child.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Procedure Overview</h2>
      <p className="text-lg mb-4">
        IVF involves retrieving eggs from a woman’s ovaries and fertilizing them
        with sperm in a lab. The fertilized egg (embryo) or eggs (embryos) are
        then transferred to a uterus.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our fertility experts use the latest technology and techniques to
        maximize the success rates of IVF treatments.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have any questions or would like to schedule an IVF consultation,
        please contact us.
      </p>
    </div>
  );
};

export default IVF;
