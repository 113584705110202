import React from 'react';

const IUI = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Intrauterine Insemination (IUI)</h1>
      <p className="text-lg mb-4">
        Intrauterine insemination (IUI) is a fertility treatment that involves placing sperm directly into a woman's uterus to facilitate fertilization.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Procedure Overview</h2>
      <p className="text-lg mb-4">
        During IUI, prepared sperm is inserted into the uterus during the ovulation period, increasing the chances of conception.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our experienced fertility specialists provide comprehensive IUI services, tailored to meet your individual needs.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        For more information or to schedule an IUI procedure, please contact us.
      </p>
    </div>
  );
};

export default IUI;
