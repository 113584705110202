import React from 'react';
import { motion } from 'framer-motion';

const WhyChooseUs = () => {
  return (
    <section className="p-8 relative">
      <div className="absolute inset-0 "></div>
      <div className="relative z-10">
        <h2 className="text-3xl font-bold text-center mb-8">Why Choose Us?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {/* Card 1 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="p-6 bg-white hover:border-2 hover:border-primary hover:bg-yellow rounded-lg shadow-lg"
          >
            <h3 className="text-xl font-bold mb-2">
              All Woman-related Treatments
            </h3>
            <p className="text-gray-700">
              All woman-related treatments ranging from consultation to
              laboratory testing are available under one roof.
            </p>
          </motion.div>

          {/* Card 2 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="p-6 bg-white hover:border-2 hover:border-primary hover:bg-yellow rounded-lg shadow-lg"
          >
            <h3 className="text-xl font-bold mb-2">
              Experience of More Than 14 Years
            </h3>
            <p className="text-gray-700">
              Clinical expertise with over 14 years of experience available for
              you 24/7.
            </p>
          </motion.div>

          {/* Card 3 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="p-6 bg-white hover:border-2 hover:border-primary hover:bg-yellow rounded-lg shadow-lg"
          >
            <h3 className="text-xl font-bold mb-2">Evidence Based Practices</h3>
            <p className="text-gray-700">
              Quality systems and evidence-based practice.
            </p>
          </motion.div>

          {/* Card 4 */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="p-6 bg-white hover:border-2 hover:border-primary hover:bg-yellow rounded-lg shadow-lg"
          >
            <h3 className="text-xl font-bold mb-2">Our Approach</h3>
            <p className="text-gray-700">Transparency, ethics, and values.</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
