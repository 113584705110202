import React from 'react';

const EndometriosisCancer = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Endometriosis and Cancer</h1>
      <p className="text-lg mb-4">
        Endometriosis is a condition where tissue similar to the lining inside
        the uterus grows outside of it, which can sometimes lead to cancer. Our
        services focus on the diagnosis and treatment of endometriosis and
        associated cancer risks.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Services Offered</h2>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Diagnosis and staging</li>
        <li>Surgical treatment</li>
        <li>Medical management</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our team of specialists is dedicated to providing comprehensive care for
        patients with endometriosis, ensuring the best possible outcomes.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have any questions or would like to schedule an appointment,
        please contact us.
      </p>
    </div>
  );
};

export default EndometriosisCancer;
