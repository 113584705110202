import React from 'react';
import { motion } from 'framer-motion';

const ContactForm = () => {
  return (
    <>
      {/* <motion.section
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative"
      >
       
        <div className="absolute inset-0 bg-gradient-to-r from-primary to-secondary z-10"></div>

        <div className="container mx-auto flex flex-col md:flex-row items-center relative z-20">
        
          <div className="md:w-1/2 max-w-lg mx-auto bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-3xl font-bold mb-4 text-center text-white">
              Fix an Appointment
            </h2>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  className="w-full p-3 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="w-full p-3 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Phone</label>
                <input
                  type="tel"
                  className="w-full p-3 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Message</label>
                <textarea className="w-full p-3 border border-gray-300 rounded"></textarea>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="bg-yellow-500 text-white px-4 py-3 rounded w-full shadow-md hover:bg-yellow-600 transition duration-300"
              >
                Check Availability
              </motion.button>
            </form>
          </div>

         
          <div className="md:w-1/2 hidden md:block">
            <img
              src="https://img.freepik.com/premium-photo/female-doctor-having-phone-call-medical-office_358354-12057.jpg"
              alt="Doctor"
              className="rounded-lg shadow-lg object-cover"
            />
          </div>
        </div>
      </motion.section> */}

      <motion.section
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="p-8 relative overflow-hidden  py-20 bg-primary bg-opacity-40"
      >
        {/* Background element with rotated background */}

        <div className="container mx-auto flex flex-col gap-5 md:flex-row items-center relative z-10">
          {/* Content section */}
          <div className="md:w-1/2 md:ml-8 relative z-20">
            <div className="bg-primary shado py-6 px-8 rounded-lg  transform md:-skew-y-12 -skew-y-6">
              <h2 className="text-3xl font-bold mb-4 text-center ">
                Fix an Appointment
              </h2>
              <form className="space-y-4">
                <div>
                  <label className="block text-gray-700">Name</label>
                  <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Email</label>
                  <input
                    type="email"
                    className="w-full p-3 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Phone</label>
                  <input
                    type="tel"
                    className="w-full p-3 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Message</label>
                  <textarea className="w-full p-3 border border-gray-300 rounded"></textarea>
                </div>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  className="hover:bg-yellow border-yellow border-t-4 border-2 bg-opacity-50 text-white px-4 py-3 rounded w-full shadow-md hover:bg-yellow-600 transition duration-300"
                >
                  Check Availability
                </motion.button>
              </form>
            </div>
          </div>{' '}
          {/* Image section */}
          <div className=" relative z-20">
            <div className="overflow-hidden rounded-lg shadow-lg transform md:skew-y-12 -skew-y-6">
              <img
                src="https://s3.envato.com/files/424017044/2504895.jpg"
                alt="Dr. Arpana Jain"
                className="md:h-[75vh] h-full "
              />
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
};

export default ContactForm;
