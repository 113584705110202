import React from 'react';

const UrinaryProblems = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Urinary Problems</h1>
      <p className="text-lg mb-4">
        Urinary problems can significantly affect your quality of life and may
        indicate underlying health issues. Whether you're experiencing pain,
        frequency, or other discomforts related to urination, seeking prompt
        medical attention is crucial for diagnosis and treatment.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Common Urinary Problems</h2>
      <p className="text-lg mb-4">
        Our clinic offers comprehensive evaluation and treatment for a range of
        urinary issues, including:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Urinary tract infections (UTIs)</li>
        <li>Urinary incontinence (involuntary leakage of urine)</li>
        <li>Overactive bladder (frequent or sudden urges to urinate)</li>
        <li>Interstitial cystitis (chronic bladder pain or discomfort)</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Diagnostic Approach</h2>
      <p className="text-lg mb-4">
        Accurate diagnosis of urinary problems often involves a thorough medical
        history review, physical examination, and diagnostic tests such as urine
        analysis, ultrasound, or specialized imaging. Our healthcare
        professionals are committed to identifying the underlying cause of your
        symptoms to provide effective treatment.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Treatment Options</h2>
      <p className="text-lg mb-4">
        Treatment for urinary problems varies depending on the specific
        condition and its severity. Our clinic offers personalized treatment
        plans that may include:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Antibiotics for bacterial infections like UTIs</li>
        <li>Medications to relax bladder muscles or reduce inflammation</li>
        <li>Behavioral therapies for managing urinary incontinence</li>
        <li>
          Lifestyle changes, such as dietary adjustments or pelvic floor
          exercises
        </li>
        <li>Surgical interventions for more complex cases.</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our clinic is dedicated to providing compassionate care and effective
        solutions for urinary problems. We prioritize your comfort, privacy, and
        overall well-being throughout your treatment journey.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you're experiencing urinary problems or have concerns about your
        urinary health, please contact us to schedule an appointment. Our
        experienced team is here to help you achieve optimal urinary health and
        improve your quality of life.
      </p>
    </div>
  );
};

export default UrinaryProblems;
