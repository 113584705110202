import React from 'react';

const OvulationStudy = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Ovulation Study</h1>
      <p className="text-lg mb-4">
        An ovulation study involves monitoring a woman's ovulation cycle to help determine the best time for conception. This can be crucial for diagnosing and treating infertility.
      </p>
      <h2 className="text-2xl font-semibold mb-2">What to Expect</h2>
      <p className="text-lg mb-4">
        During an ovulation study, various tests such as ultrasound and blood tests are conducted to track the ovulation process.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our fertility specialists use advanced techniques to accurately monitor ovulation, helping you achieve your fertility goals.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have any questions or need to schedule an ovulation study, please contact us.
      </p>
    </div>
  );
};

export default OvulationStudy;
