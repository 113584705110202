import React from 'react';

const MaleFertility = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Male Fertility</h1>
      <p className="text-lg mb-4">
        Male fertility involves the study and treatment of the male reproductive system. Our services are dedicated to helping men with fertility issues.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Services Offered</h2>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Semen analysis</li>
        <li>Hormonal evaluation</li>
        <li>Surgical interventions</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our team of experts is committed to providing compassionate and effective care to help men achieve their fertility goals.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Confidential and Respectful Care</h2>
      <p className="text-lg mb-4">
        We ensure that all our services are provided in a confidential and respectful manner, making our patients feel comfortable.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have any questions or would like to schedule an appointment, please don't hesitate to contact us.
      </p>
    </div>
  );
};

export default MaleFertility;
