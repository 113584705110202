import React from 'react';

const DiagnosticHysteroLaparoscopy = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Diagnostic Hystero Laparoscopy</h1>
      <p className="text-lg mb-4">
        Diagnostic hystero laparoscopy is a minimally invasive procedure used to examine the reproductive organs. This procedure helps in diagnosing various gynecological conditions.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Procedure Overview</h2>
      <p className="text-lg mb-4">
        A small camera is inserted through the abdomen to visualize the pelvic organs. This allows for accurate diagnosis of issues such as endometriosis, fibroids, and adhesions.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our skilled surgeons ensure that the diagnostic hystero laparoscopy is performed with precision, providing valuable insights for your treatment plan.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        For more information or to schedule a diagnostic hystero laparoscopy, please contact us.
      </p>
    </div>
  );
};

export default DiagnosticHysteroLaparoscopy;
