import React from 'react';

const PhotoGallery = () => {
  return (
    <div className="container mx-auto my-10 p-6">
      <h1 className="text-3xl font-bold mb-4">Photo Gallery</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <img
          src="https://media.istockphoto.com/id/1292015214/photo/portrait-female-doctor-stock-photo.jpg?s=612x612&w=0&k=20&c=nr4XaWnRPQnWqwhzahajZhskIDG1yK9DmIteV5gpUOI="
          alt="Gallery Image 1"
          className="w-full h-auto rounded shadow-lg"
        />
        <img
          src="https://t4.ftcdn.net/jpg/03/20/74/45/360_F_320744517_TaGkT7aRlqqWdfGUuzRKDABtFEoN5CiO.jpg"
          alt="Gallery Image 2"
          className="w-full h-auto rounded shadow-lg"
        />
        <img
          src="https://img.freepik.com/free-photo/expressive-young-woman-posing-studio_176474-66978.jpg?size=626&ext=jpg&ga=GA1.1.1141335507.1719100800&semt=ais_user"
          alt="Gallery Image 3"
          className="w-full h-auto rounded shadow-lg"
        />
      </div>
    </div>
  );
};

export default PhotoGallery;
