import React from 'react';

const FemaleFertility = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Female Fertility</h1>
      <p className="text-lg mb-4">
        Female fertility covers the diagnosis and treatment of issues related to the female reproductive system. We provide comprehensive care to help women achieve their fertility goals.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Services Offered</h2>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Ovulation induction</li>
        <li>Hormonal treatments</li>
        <li>Surgical interventions</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our dedicated team offers personalized and compassionate care to support women through their fertility journey.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Confidential and Respectful Care</h2>
      <p className="text-lg mb-4">
        We prioritize privacy and dignity, ensuring that all our patients feel comfortable and respected.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        For any inquiries or to schedule an appointment, please contact us.
      </p>
    </div>
  );
};

export default FemaleFertility;
