import React from 'react';

const VaginalDischarge = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Vaginal Discharge</h1>
      <p className="text-lg mb-4">
        Vaginal discharge is a normal occurrence in women, serving to cleanse and protect the vagina. However, changes in color, consistency, or odor of vaginal discharge may indicate an infection or other underlying health issues that require medical attention.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Common Causes of Abnormal Vaginal Discharge</h2>
      <p className="text-lg mb-4">
        Our clinic specializes in diagnosing and treating various causes of abnormal vaginal discharge, including:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Bacterial vaginosis (BV)</li>
        <li>Yeast infections (Candidiasis)</li>
        <li>Sexually transmitted infections (STIs) such as trichomoniasis, gonorrhea, or chlamydia</li>
        <li>Changes in hormonal balance</li>
        <li>Foreign bodies or irritants</li>
        <li>Pelvic inflammatory disease (PID)</li>
        <li>Cervical or vaginal cancer (less common)</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Symptoms and Diagnosis</h2>
      <p className="text-lg mb-4">
        It's important to pay attention to any changes in vaginal discharge, along with accompanying symptoms like itching, burning, or pain during urination or intercourse. Diagnosis typically involves a pelvic examination, laboratory tests (such as vaginal swab cultures), and possibly imaging studies.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Treatment Options</h2>
      <p className="text-lg mb-4">
        Treatment for vaginal discharge depends on the underlying cause and may include:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Antibiotics or antifungal medications</li>
        <li>Topical treatments (creams or suppositories)</li>
        <li>Medications to restore hormonal balance</li>
        <li>Behavioral changes (such as avoiding irritants or improving hygiene practices)</li>
        <li>Partner treatment for sexually transmitted infections</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Prevention and Care</h2>
      <p className="text-lg mb-4">
        Our clinic provides comprehensive care to manage vaginal discharge, emphasizing prevention strategies and patient education. We empower women to maintain optimal vaginal health through regular screenings, safe sexual practices, and healthy lifestyle choices.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you're experiencing abnormal vaginal discharge or have concerns about your vaginal health, please contact us to schedule an appointment. Our caring team is dedicated to providing confidential and effective care to help you feel comfortable and healthy.
      </p>
    </div>
  );
};

export default VaginalDischarge;
