import React from 'react';

const ScreeningCervicalCancer = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-white text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Screening of Cervical Cancer</h1>
      <p className="text-lg mb-4">
        Screening for cervical cancer involves tests and procedures to detect pre-cancerous conditions and early stages of cervical cancer. Early detection significantly improves treatment outcomes.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Screening Methods</h2>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Pap smear (Pap test)</li>
        <li>HPV testing</li>
        <li>Colposcopy</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">Why Choose Us?</h2>
      <p className="text-lg mb-4">
        Our team of healthcare professionals is dedicated to providing thorough and accurate screening services, ensuring early detection and effective management of cervical cancer.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you have any questions or would like to schedule a screening appointment, please contact us.
      </p>
    </div>
  );
};

export default ScreeningCervicalCancer;
