import React from 'react';

const MenstrualProblems = () => {
  return (
    <div className="container mx-auto py-10 px-4 bg-secondary text-black rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Menstrual Problems</h1>
      <p className="text-lg mb-4">
        Menstrual problems can significantly impact a woman's quality of life.
        Whether it's irregular periods, heavy bleeding, or severe menstrual
        cramps, our specialized care is designed to diagnose and treat a wide
        range of menstrual issues.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Common Menstrual Problems</h2>
      <p className="text-lg mb-4">
        Our clinic offers comprehensive evaluation and treatment for various
        menstrual problems, including:
      </p>
      <ul className="list-disc list-inside text-lg mb-4">
        <li>Irregular menstrual cycles</li>
        <li>Heavy menstrual bleeding (Menorrhagia)</li>
        <li>Painful periods (Dysmenorrhea)</li>
        <li>Absence of menstruation (Amenorrhea)</li>
        <li>Premenstrual Syndrome (PMS)</li>
        <li>Premenstrual Dysphoric Disorder (PMDD)</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">
        Personalized Treatment Plans
      </h2>
      <p className="text-lg mb-4">
        We understand that each woman's experience with menstrual problems is
        unique. Our healthcare providers work closely with you to develop
        personalized treatment plans that address your specific symptoms and
        needs. Treatment options may include medication, lifestyle changes, and,
        in some cases, surgical interventions.
      </p>
      {/* <h2 className="text-2xl font-semibold mb-2">
        Advanced Diagnostic Services
      </h2>
      <p className="text-lg mb-4">
        Accurate diagnosis is crucial for effective treatment. We offer advanced
        diagnostic services, including ultrasound, hormonal testing, and other
        specialized tests, to identify the underlying causes of menstrual
        problems.
      </p> */}
      <h2 className="text-2xl font-semibold mb-2">Holistic Approach to Care</h2>
      <p className="text-lg mb-4">
        Our approach to treating menstrual problems is holistic, considering all
        aspects of your health and well-being. We provide education and support
        to help you manage your condition and improve your overall quality of
        life.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="text-lg mb-4">
        If you are experiencing menstrual problems, don't hesitate to reach out
        to us. Our dedicated team is here to help you find relief and achieve
        optimal health.
      </p>
    </div>
  );
};

export default MenstrualProblems;
