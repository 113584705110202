import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { FaLocationArrow } from 'react-icons/fa';
import logocharu from '../Images/charu_logo-removebg-preview.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-primary text-black px-4  fixed top-0 w-full z-50">
      <div className="container mx-auto flex md:flex-row flex-col justify-between items-center">
        <div className="flex justify-between items-center gap-40">
          <Link to="/" className="flex-shrink-0">
            <img src={logocharu} alt="" className="w-16 h-16 object-contain" />
          </Link>
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <XIcon className="w-6 h-6" />
              ) : (
                <MenuIcon className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>

        <div
          className={`md:flex ${isOpen ? 'block' : 'hidden'} w-full md:w-auto`}
        >
          <div
            className={`md:flex ${
              isOpen ? 'block' : 'hidden'
            } md:flex-row flex-col w-full md:w-auto`}
          >
            <NavLink
              to="/"
              exact
              className="px-4 py-2 md:py-0 inline-block"
              activeClassName="text-yellow border-b-2 border-yellow"
            >
              Home
            </NavLink>
            {/* About Dropdown */}
            <div className="relative group">
              <button className="px-4 py-2 md:py-0 inline-block">About</button>
              <div className="hidden absolute group-hover:block group-hover:border-t-2 group-hover:border-yellow bg-primary mt-1 py-10 rounded shadow-lg z-50 mx-auto">
                <div className="container mx-auto">
                  <div className="flex justify-start flex-col whitespace-nowrap">
                    <NavLink
                      to="/about"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Dr.Charu Maheshwari
                    </NavLink>
                    <NavLink
                      to="/photo-gallery"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Photo Gallery
                    </NavLink>
                    <NavLink
                      to="/testimonials"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Testimonials
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* Gynaecology Dropdown */}
            <div className="relative group">
              <button className="px-4 py-2 md:py-0 inline-block">
                Gynaecology
              </button>
              <div className="hidden absolute group-hover:block group-hover:border-t-2 group-hover:border-yellow bg-primary mt-1 py-10 rounded shadow-lg z-50 mx-auto">
                <div className="container mx-auto">
                  <div className="flex justify-center flex-col whitespace-nowrap">
                    <NavLink
                      to="/gynaecology/adolescent-medicine"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Adolescent Medicine
                    </NavLink>
                    <NavLink
                      to="/gynaecology/menstrual-problems"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Menstrual Problems
                    </NavLink>
                    <NavLink
                      to="/gynaecology/pcos"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      PCOS
                    </NavLink>
                    <NavLink
                      to="/gynaecology/urinary-problems"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Urinary Problems
                    </NavLink>
                    <NavLink
                      to="/gynaecology/vaginal-discharge"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Vaginal Discharge
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* Obstetrics Dropdown */}
            <div className="relative group">
              <button className="px-4 py-2 md:py-0 inline-block">
                Obstetrics
              </button>
              <div className="hidden absolute group-hover:block group-hover:border-t-2 group-hover:border-yellow bg-primary mt-1 py-10 rounded shadow-lg z-50 mx-auto">
                <div className="container mx-auto">
                  <div className="flex justify-center flex-col whitespace-nowrap">
                    <NavLink
                      to="/obstetrics/pre-pregnancy-care"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Pre Pregnancy Care
                    </NavLink>
                    <NavLink
                      to="/obstetrics/pregnancy-care"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Pregnancy Care
                    </NavLink>
                    <NavLink
                      to="/obstetrics/laparoscopy-ectopic-pregnancy"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Laparoscopy
                    </NavLink>
                    <NavLink
                      to="/obstetrics/recurrent-pregnancy-loss"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Recurrent Pregnancy Loss
                    </NavLink>
                    <NavLink
                      to="/obstetrics/pregnancy-ultrasound-scan"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Pregnancy Ultrasound Scan
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* infertility Dropdown */}
            <div className="relative group">
              <button className="px-4 py-2 md:py-0 inline-block">
                Infertility
              </button>
              <div className="hidden absolute group-hover:block group-hover:border-t-2 group-hover:border-yellow bg-primary mt-1 py-10 rounded shadow-lg z-50 mx-auto">
                <div className="container mx-auto">
                  <div className="flex justify-center flex-col whitespace-nowrap">
                    <NavLink
                      to="/infertility/male-fertility"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Male Fertility
                    </NavLink>
                    <NavLink
                      to="/infertility/female-fertility"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Female Fertility
                    </NavLink>
                    <NavLink
                      to="/infertility/hsg-diagnostic-hystero-laparoscopy"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      HSG Diagnostic Hystero Laparoscopy
                    </NavLink>
                    <NavLink
                      to="/infertility/ovulation-study"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Ovulation Study
                    </NavLink>
                    <NavLink
                      to="/infertility/iui"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      IUI
                    </NavLink>
                    <NavLink
                      to="/infertility/ivf"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      IVF
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {/* Gynaec oncology Dropdown */}
            <div className="relative group">
              <button className="px-4 py-2 md:py-0 inline-block">
                Gynaec oncology{' '}
              </button>
              <div className="hidden absolute group-hover:block group-hover:border-t-2 group-hover:border-yellow bg-primary mt-1 py-10 rounded shadow-lg z-50 mx-auto">
                <div className="container mx-auto">
                  <div className="flex justify-center flex-col whitespace-nowrap">
                    <NavLink
                      to="/cervical-cancer-screening"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Screening of Cervical Cancer
                    </NavLink>
                    <NavLink
                      to="/cervical-cancer-vaccination"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Vaccination of Cervical Cancer
                    </NavLink>
                    <NavLink
                      to="/endometriosis-cancer"
                      className="block px-4 py-2 md:py-0 inline-flex items-center hover:text-yellow"
                      activeClassName="text-yellow"
                    >
                      <FaLocationArrow className="text-sm mt-1 mr-2" />
                      Endometriosis Cancer
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Us Link */}
            <NavLink
              to="/contact"
              className="px-4 py-2 md:py-0 inline-block"
              activeClassName="text-yellow border-b-2 border-yellow"
            >
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
