import React from 'react';
import { FaLocationArrow } from 'react-icons/fa';

const About = () => {
  return (
    <div id="about" className="relative bg-white overflow-hidden mt-16">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100"></polygon>
          </svg>

          <div className="pt-1"></div>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                About Me
              </h2>

              <p className="mb-4">
                Dr. Charu Maheshwari is a compassionate and experienced
                obstetrician-gynecologist who provides comprehensive care for
                women of all ages. With extensive training in various
                gynecological procedures, including laparoscopy, she is
                well-equipped to address a wide range of women's health issues.
                Dr. Maheshwari is dedicated to offering individualized care,
                ensuring that each patient feels comfortable and supported
                throughout their treatment journey.
              </p>
              <p className="mb-4">
                At her clinic in Indore, Dr. Charu Maheshwari offers a broad
                spectrum of services, including:
              </p>

              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                Obstetrics
              </h3>
              <p className="mb-4">
                From preconception counseling to postnatal care, Dr. Maheshwari
                provides expert guidance and support throughout pregnancy. She
                is committed to ensuring the health and well-being of both
                mother and baby, offering services such as routine prenatal
                check-ups, high-risk pregnancy management, and delivery
                planning.
              </p>

              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                Gynecology
              </h3>
              <p className="mb-4">
                Dr. Maheshwari addresses a wide range of gynecological concerns,
                from menstrual irregularities and pelvic pain to menopausal
                symptoms. She offers thorough evaluations, accurate diagnoses,
                and effective treatments tailored to each patient's unique
                needs.
              </p>

              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                Laparoscopic Surgery
              </h3>
              <p className="mb-4">
                As a specialist in minimally invasive gynecological surgery, Dr.
                Maheshwari performs laparoscopic procedures for conditions such
                as fibroids, ovarian cysts, endometriosis, and ectopic
                pregnancies. These advanced techniques offer reduced pain,
                faster recovery times, and minimal scarring compared to
                traditional surgery.
              </p>

              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                Infertility Treatment
              </h3>
              <p className="mb-4">
                Dr. Maheshwari understands the emotional and physical challenges
                of infertility. She provides comprehensive infertility
                evaluations and personalized treatment plans, including
                ovulation induction, intrauterine insemination (IUI), and
                referrals for advanced reproductive technologies like IVF.
              </p>

              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                Preventive Care
              </h3>
              <p className="mb-4">
                Emphasizing the importance of preventive health, Dr. Maheshwari
                offers routine screenings and health check-ups, including Pap
                smears, HPV vaccinations, and breast examinations. Her goal is
                to promote long-term health and well-being through early
                detection and prevention of gynecological conditions.
              </p>

              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                Adolescent Gynecology
              </h3>
              <p className="mb-4">
                Dr. Maheshwari provides specialized care for adolescent girls,
                addressing issues such as menstrual irregularities, puberty
                concerns, and contraceptive counseling in a sensitive and
                age-appropriate manner.
              </p>

              <p className="mb-4">
                Dr. Charu Maheshwari's clinic in Indore is equipped with
                state-of-the-art facilities, ensuring patients receive the
                highest quality care in a comfortable and welcoming environment.
                Her commitment to staying updated with the latest advancements
                in obstetrics and gynecology ensures that patients benefit from
                the most current and effective treatments available.
              </p>

              <p className="mb-4">
                Visit Dr. Charu Maheshwari's clinic in Indore, Madhya Pradesh,
                to experience her expertise and dedication to women's health.
                Whether you need routine gynecological care, specialized
                treatment, or support through pregnancy and childbirth, Dr.
                Maheshwari is here to provide the compassionate and personalized
                care you deserve.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://lh3.googleusercontent.com/p/AF1QipOQk9JNcQbGECFbtT_BvJIWPQOw6rruwVXBFaEO=s680-w680-h510"
          alt="Dr. Charu Maheshwari"
        />
      </div>
    </div>
  );
};

export default About;
