import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import Contact from '../components/Contact';
import Hero from '../pages/Home/Hero';
import PhotoGallery from '../pages/Aboutuse/PhotoGallery';
import Testimonials from '../pages/Aboutuse/Testimonials';
import About from '../pages/Aboutuse/About';

// Gynaecology Pages
import AdolescentMedicine from '../pages/Gynaecology/AdolescentMedicine';
import MenstrualProblems from '../pages/Gynaecology/MenstrualProblems';
import PCOS from '../pages/Gynaecology/PCOS';
import UrinaryProblems from '../pages/Gynaecology/UrinaryProblems';
import VaginalDischarge from '../pages/Gynaecology/VaginalDischarge';

// Obstetrics Pages
import PrePregnancyCare from '../pages/Obstetrics/PrePregnancyCare';
import PregnancyCare from '../pages/Obstetrics/PregnancyCare';
import Laparoscopy from '../pages/Obstetrics/Laparoscopy';
import RecurrentPregnancyLoss from '../pages/Obstetrics/RecurrentPregnancyLoss';
import PregnancyUltrasoundScan from '../pages/Obstetrics/PregnancyUltrasoundScan';

// Infertility Pages
import MaleFertility from '../pages/Infertility/MaleFertility';
import FemaleFertility from '../pages/Infertility/FemaleFertility';
import DiagnosticHysteroLaparoscopy from '../pages/Infertility/DiagnosticHysteroLaparoscopy';
import OvulationStudy from '../pages/Infertility/OvulationStudy';
import IUI from '../pages/Infertility/IUI';
import IVF from '../pages/Infertility/IVF';

// Gynaec Oncology Pages

import EndometriosisCancer from '../pages/Gynaeconcology/EndometriosisCancer';
import ScreeningCervicalCancer from '../pages/Gynaeconcology/ScreeningCervicalCancer';
import VaccinationCervicalCancer from '../pages/Gynaeconcology/VaccinationCervicalCancer';

const Routing = () => {
  return (
    <div className="">
      <Navbar />
      <main className="py-16 ">
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/photo-gallery" element={<PhotoGallery />} />
          <Route path="/testimonials" element={<Testimonials />} />

          {/* Gynaecology Routes */}
          <Route
            path="/gynaecology/adolescent-medicine"
            element={<AdolescentMedicine />}
          />
          <Route
            path="/gynaecology/menstrual-problems"
            element={<MenstrualProblems />}
          />
          <Route path="/gynaecology/pcos" element={<PCOS />} />
          <Route
            path="/gynaecology/urinary-problems"
            element={<UrinaryProblems />}
          />
          <Route
            path="/gynaecology/vaginal-discharge"
            element={<VaginalDischarge />}
          />

          {/* Obstetrics Routes */}
          <Route
            path="/obstetrics/pre-pregnancy-care"
            element={<PrePregnancyCare />}
          />
          <Route
            path="/obstetrics/pregnancy-care"
            element={<PregnancyCare />}
          />
          <Route
            path="/obstetrics/laparoscopy-ectopic-pregnancy"
            element={<Laparoscopy />}
          />
          <Route
            path="/obstetrics/recurrent-pregnancy-loss"
            element={<RecurrentPregnancyLoss />}
          />
          <Route
            path="/obstetrics/pregnancy-ultrasound-scan"
            element={<PregnancyUltrasoundScan />}
          />

          {/* Infertility Routes */}
          <Route
            path="/infertility/male-fertility"
            element={<MaleFertility />}
          />
          <Route
            path="/infertility/female-fertility"
            element={<FemaleFertility />}
          />
          <Route
            path="/infertility/hsg-diagnostic-hystero-laparoscopy"
            element={<DiagnosticHysteroLaparoscopy />}
          />
          <Route
            path="/infertility/ovulation-study"
            element={<OvulationStudy />}
          />
          <Route path="/infertility/iui" element={<IUI />} />
          <Route path="/infertility/ivf" element={<IVF />} />

          {/* Gynaec Oncology Routes */}
          <Route
            path="/cervical-cancer-screening"
            element={<ScreeningCervicalCancer />}
          />
          <Route
            path="/cervical-cancer-vaccination"
            element={<VaccinationCervicalCancer />}
          />
          <Route
            path="/endometriosis-cancer"
            element={<EndometriosisCancer />}
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default Routing;
